<script>
import { mapActions, mapGetters } from 'vuex'
import { solutionTypeFormatter } from '@/support/utils/solutionTypeFormatter'

export default {
  name: 'SolutionsList',
  components: {
    Action: () => import('@/components/general/Action'),
    Dropdown: () => import('@/components/general/Dropdown'),
    Datatable: () => import('@/components/general/Datatable'),
    FilterList: () => import('@/components/general/FilterList'),
    Pagination: () => import('@/components/general/Pagination'),
    DropdownLink: () => import('@/components/general/DropdownLink'),
    ContentHeader: () => import('@/components/general/ContentHeader'),
    FilterListSearch: () => import('@/components/general/FilterListSearch'),
    ModalConfirm: () => import('@/components/macro/ModalConfirm'),
    Icon: () => import('@/components/general/Icon'),
    Modal: () => import('@/components/general/Modal'),
    Tabs: () => import('@/components/general/Tabs'),
    SelectField: () => import('@/components/general/SelectField')
  },

  data () {
    return {
      pagination: {
        query: {},
        filter: {},
        order: {
          active: 'desc',
          name: 'asc'
        },

        limit: 12,
        page: 1,
        lastPage: null
      },

      modalRemoveOpen: false,
      modalDeactivateOpen: false,
      solutionId: null,
      solutionsList: [],
      modalSessionsFiltersOpen: false,
      filteredFormLabels: {
        active: [
          {
            text: this.$t('solutions.manage:filtered-form.input.1.option.1'),
            value: 'true'
          },
          {
            text: this.$t('solutions.manage:filtered-form.input.1.option.2'),
            value: 'false'
          }
        ],

        showOnPortal: [
          {
            text: this.$t('global:form.tracks.yes'),
            value: '1'
          },
          {
            text: this.$t('global:form.tracks.no'),
            value: '0'
          }
        ],

        hasCertificate: [
          {
            text: this.$t('solutions.manage:filtered-form.input.3.option.1'),
            value: '1'
          },
          {
            text: this.$t('solutions.manage:filtered-form.input.3.option.2'),
            value: '0'
          }
        ],

        hasSurvey: [
          {
            text: this.$t('solutions.manage:filtered-form.input.4.option.1'),
            value: '1'
          },
          {
            text: this.$t('solutions.manage:filtered-form.input.4.option.2'),
            value: '0'
          }
        ],

        enrollmentClosed: [
          {
            text: this.$t('global:form.tracks.yes'),
            value: '"closed"'
          },
          {
            text: this.$t('global:form.tracks.no'),
            value: '"open"'
          }
        ]
      },

      filteredForm: {
        active: null,
        show_on_portal: null,
        has_certificate: null,
        has_survey: null,
        enrollment_closed: null
      },

      tabLinks: [
        {
          location: { name: 'solutions.index' },
          text: this.$t('solutions.tabs:link.1')
        },
        {
          location: { name: 'solutions.configs' },
          text: this.$t('solutions.tabs:link.2')
        }
      ]
    }
  },

  computed: {
    ...mapGetters([
      'getPagination',
      'activeModules'
    ]),

    isRaeEnabled () {
      return this.activeModules.includes('rae')
    },

    isSasEnabled () {
      return this.activeModules.includes('sas')
    },

    isEcommerceEnabled () {
      return this.activeModules.includes('ecommerce')
    }
  },

  watch: {
    'pagination.page' () {
      this.getSolutionList()
    }
  },

  created () {
    this.pagination = this.getPagination
    this.filteredForm = this.getPagination.filter

    this.getSolutionList()
  },

  methods: {
    ...mapActions([
      'setFetching',
      'setFeedback',
      'setPagination',
      'attemptGetSolutionsList',
      'attemptManageSolution',
      'attemptRemoveSolution',
      'attemptToggleActiveSolution'
    ]),

    prevPage () {
      if (this.pagination.page > 1) {
        this.pagination.page -= 1
      }
    },

    nextPage () {
      this.pagination.page += 1
    },

    firstPage () {
      this.pagination.page = 1
    },

    lastPage () {
      this.pagination.page = this.pagination.lastPage
    },

    createNewSolution () {
      this.attemptManageSolution(null)
      this.$router.push({ name: 'solutions.manage' })
    },

    searchSolution (search) {
      this.pagination.query = {
        name: search,
        only_name: true
      }

      if (this.pagination.page === 1) {
        this.getSolutionList()
      } else {
        this.pagination.page = 1
      }
    },

    edit (solutionId, tab = 1) {
      this.setFetching(true)

      this.attemptManageSolution(solutionId).then(() => {
        this.$router.push({
          name: 'solutions.manage',
          params: {
            id: solutionId,
            tab: tab
          }
        })
      }).finally(() => {
        this.setFetching(false)
      })
    },

    toggleActive (solutionId, toggleFlag) {
      this.setFetching(true)

      this.attemptToggleActiveSolution(solutionId).then(() => {
        this.getSolutionList()

        if (!toggleFlag) {
          this.setFeedback({ message: this.$t('solutions:feedback.toggle.activate.success') })
        } else {
          this.setFeedback({ message: this.$t('solutions:feedback.toggle.deactivate.success') })
        }
      }).catch((e) => {
        if (e.data.error.message === 'toggle_solution_unable_to_create_event') {
          this.setFeedback({ message: this.$t('solutions:feedback.toggle.integration.error', { integration: 'SAS' }) })
        } else if (this.getErrorMessage(e) === 'cant_activate_solution_no_asset_found') {
          this.setFeedback({ message: this.$t('management.solutions:error.asset.not.found') })
        } else if (e.data.error.message === 'solution__invalid_portfolio') {
          this.setFeedback({ message: this.$t('solutions:feedback.portfolio.invalid') })
        } else if (e.data.error.message === 'solution__portfolio_not_registered') {
          this.setFeedback({ message: this.$t('solutions:feedback.portfolio.not.registered') })
        } else {
          this.setFeedback({ message: this.$t('global.error') })
        }
      }).finally(() => {
        this.setFetching(false)
        this.modalDeactivateOpen = false
      })
    },

    openPage (track) {
      this.attemptManageSolution(track.id).then((data) => {
        if (data.exclusiveToThematicPages === true) {
          window.open(`${process.env.VUE_APP_PORTAL_URL}parceiro/${data.thematicPageSolution[0].thematicPage.slug}/curso/${track.slug}`, '_blank')
        } else {
          window.open(`${process.env.VUE_APP_PORTAL_URL}curso/${data.slug}`, '_blank')
        }
      }).finally(() => {
        this.setFetching(false)
      })
    },

    remove () {
      // this.modalRemoveOpen = false
      // this.setFetching(true)
      // this.attemptRemoveSolution(this.solutionId).then(() => {
      //   this.setFeedback({ message: this.$t(`solutions:feedback.remove.success`) })
      //   this.getSolutionList()
      // }).catch(() => {
      //   this.setFeedback({ message: this.$t(`solutions:feedback.remove.error`) })
      // }).finally(() => {
      //   this.setFetching(false)
      // })
    },

    deactivate () {
      this.toggleActive(this.solutionId, true)
    },

    // confirmRemove (idSolution) {
    // this.solutionId = idSolution
    // this.modalRemoveOpen = true
    // },

    confirmDeactivate (idSolution) {
      this.solutionId = idSolution
      this.modalDeactivateOpen = true
    },

    doCopy (idSolution) {
      const copiedText = `${process.env.VUE_APP_PORTAL_URL}matricular/${idSolution}`

      this.$copyText(copiedText).then(() => {
        this.setFeedback({ message: `Link copiado!<br /> ${copiedText}` })
      }, () => {
        this.setFeedback({ message: `Copie o link de matrícula:<br /> ${copiedText}` })
      })
    },

    cancelDeactivate () {
      this.modalDeactivateOpen = false
    },

    cancelRemove () {
      this.modalRemoveOpen = false
    },

    getSASId (integration) {
      if (!integration) return '--'

      return integration.data && integration.data.sasId ? integration.data.sasId : '--'
    },

    getPortfolioId (integration) {
      if (!integration) return '--'

      return integration.data && integration.data.portfolioId ? integration.data.portfolioId : '--'
    },

    getErrorMessage (e) {
      if (e.data && e.data.error && e.data.error.message) {
        return e.data.error.message
      }

      return null
    },

    displayIntegrationError (solution) {
      if (!solution.active && solution.solutionIntegration.length > 0 && solution.solutionIntegration[0].data.lastIntegration) {
        return !solution.solutionIntegration[0].data.lastIntegration.success
      }
    },

    displayPortfolioIntegrationError (solution) {
      if (solution.solutionIntegration.length > 0 && solution.solutionIntegration[0].data.lastPortfolioIntegration) {
        return !solution.solutionIntegration[0].data.lastPortfolioIntegration.success
      }
    },

    tooltipContent (solution) {
      const error = this.$t('solutions.list:integration.error')
      const code = this.$t('global:code') + ': ' + solution.solutionIntegration[0].data.lastIntegration.code
      const msg = this.$t('global:message') + ': ' + solution.solutionIntegration[0].data.lastIntegration.message

      return `
        <p>${error}</p></br>
        <span>${code}</span></br>
        <span>${msg}</span>
      `
    },

    doSearch () {
      return this.getPagination && this.getPagination.query ? this.getPagination.query.name : null
    },

    getSolutionList () {
      this.setFetching(true)
      this.setPagination(this.pagination)

      this.attemptGetSolutionsList(this.pagination).then(pagination => {
        this.pagination.lastPage = pagination.lastPage
        this.solutionsList = pagination.data
      }).finally(() => {
        this.setFetching(false)
      })
    },

    filter () {
      Object.keys(this.filteredForm).map(key => {
        this.pagination.filter[key] = this.filteredForm[key] || undefined

        return key
      })

      if (this.pagination.page === 1) {
        this.getSolutionList()
      } else {
        this.pagination.page = 1
      }

      this.closeFilterModal()
      this.getSolutionList()
    },

    openFilterModal () {
      this.modalSessionsFiltersOpen = true
    },

    closeFilterModal () {
      this.modalSessionsFiltersOpen = false
    },

    clearFilters () {
      Object.keys(this.filteredForm).map(key => {
        this.filteredForm[key] = undefined

        return key
      })

      if (this.pagination.page === 1) {
        this.getSolutionList()
      } else {
        this.pagination.page = 1
      }

      this.getSolutionList()
    },

    getSolutionTypeFormattedName (solutionType) {
      return solutionTypeFormatter(solutionType)
    }
  }
}
</script>

<template>
  <div class="main-content">
    <ContentHeader
      :title="$t('solutions:header.title')"
      :description="$t('solutions:header.description')"
      background="/assets/images/themes/default/png/solutions_banner.png"
    >
      <Tabs
        slot="tabs"
        dark
        :links="tabLinks"
      />
    </ContentHeader>
    <FilterList>
      <Action
        slot="button"
        primary
        large
        fixed-width
        type="button"
        :text="$t('solutions.list:btn.add')"
        @click="createNewSolution"
      />

      <Action
        slot="button"
        flat
        fixed-width
        type="button"
        :text="$t('sessions.list:btn.filter')"
        @click="openFilterModal"
      />

      <FilterListSearch
        slot="search"
        :hide-error-details="true"
        :do-search="doSearch()"
        @search="searchSolution"
      />
    </FilterList>
    <div
      v-if="solutionsList.length > 0"
      class="center solutions-table"
    >
      <Datatable :items="solutionsList">
        <template slot="thead">
          <tr>
            <th class="th">
              {{ $t('solutions.list:datatable.header.1') }}
            </th>
            <th
              class="th"
              width="170"
            >
              {{ $t('solutions.list:datatable.header.2') }}
            </th>
            <th class="th">
              {{ $t('solutions.list:datatable.header.5') }}
            </th>
            <th
              v-if="isRaeEnabled || isSasEnabled"
              class="th text-center"
            >
              {{ $t('solutions.list:datatable.header.3') }}
            </th>
            <th
              class="th text-center"
              width="80"
            >
              {{ $t('solutions.list:datatable.header.4') }}
            </th>
            <th
              class="th"
              width="40"
            />
            <th
              class="th"
              width="40"
            />
          </tr>
        </template>
        <template
          slot="tbody"
          slot-scope="props"
        >
          <tr>
            <td class="td">
              <span class="td-text bolder">
                {{ props.item.name }}
              </span>
            </td>
            <td class="td">
              <span class="td-text">{{ getSolutionTypeFormattedName(props.item.solutionType) }}</span>
            </td>
            <td class="td">
              <span class="td-text">{{ props.item.solutionTypeIntegration.integration.name.toLowerCase() == 'local' ? '-' : props.item.solutionTypeIntegration.integration.name }}</span>
            </td>
            <td
              v-if="isRaeEnabled || isSasEnabled"
              class="td text-center"
            >
              <span class="td-text">{{ getPortfolioId(props.item.solutionIntegration[0]) }} / {{ getSASId(props.item.solutionIntegration[0]) }}</span>
            </td>
            <td class="td text-center">
              <span
                v-if="!!props.item.active"
                class="td-tag active-ribbon"
              >{{ $t('global.status:active') }}</span>
              <span
                v-else
                class="td-tag inactive-ribbon"
              >{{ $t('global.status:inactive') }}</span>
            </td>
            <td class="td text-center">
              <span
                v-if="displayIntegrationError(props.item)"
                v-tooltip="{
                  content: tooltipContent(props.item),
                  placement: 'bottom-center',
                  classes: ['info'],
                  targetClasses: ['it-has-a-tooltip'],
                  offset: 15,
                  trigger: 'click'
                }"
                class="warning"
              >
                <Icon name="alert-circle" />
              </span>
              <VPopover
                v-if="displayPortfolioIntegrationError(props.item) && isRaeEnabled"
              >
                <Icon name="alert-circle" />
                <template slot="popover">
                  <p>{{ $t('solutions.list:integration.error') }}</p>
                  <span>{{ $t('global:code') + ': ' + props.item.solutionIntegration[0].data.lastPortfolioIntegration.code }}</span>
                  <span>{{ $t('global:message') + ': ' + props.item.solutionIntegration[0].data.lastPortfolioIntegration.message }}</span>
                </template>
              </VPopover>
              <span
                v-if="props.item.metadata.assetUnavailable === true"
                v-tooltip="{
                  content: $t('solutions.asset.file:fetch.error.tooltip'),
                  placement: 'bottom-center',
                  classes: ['info'],
                  targetClasses: ['it-has-a-tooltip'],
                  offset: 15,
                  trigger: 'click'
                }"
                class="warning"
              >
                <Icon name="alert-circle" />
              </span>
              <span
                v-if="props.item.paymentType === 'paid' && !isEcommerceEnabled"
                v-tooltip="{
                  content: $t('solutions.list:datatable.tooltip.paid'),
                  placement: 'auto',
                  classes: ['info'],
                  targetClasses: ['it-has-a-tooltip'],
                  offset: 10,
                  trigger: 'hover'
                }"
                class="warning"
              >
                <Icon name="alert-circle" />
              </span>
            </td>
            <!--
            <td class="td td-button td-small text-right">
-->
            <!--
              <action type="button" :text="$t('global:view.more')" flat @click="edit(props.item.id)"></action>
-->
            <!--
            </td>
-->
            <td
              class="td td-actions"
              width="40"
            >
              <Dropdown
                icon="dots-vertical"
                right
              >
                <DropdownLink
                  :text="$t('global:edit')"
                  @click="edit(props.item.id, 1)"
                />
                <DropdownLink
                  :text="$t('global:qualify')"
                  @click="edit(props.item.id, 2)"
                />
                <DropdownLink
                  :text="$t('global:seo')"
                  @click="edit(props.item.id, 3)"
                />
                <DropdownLink
                  :text="$t('global:links')"
                  @click="edit(props.item.id, 4)"
                />
                <DropdownLink
                  v-if="isRaeEnabled || isSasEnabled"
                  :text="$t('global:integrations')"
                  @click="edit(props.item.id, 5)"
                />
                <DropdownLink
                  :text="$t('global:show.page')"
                  @click="openPage(props.item)"
                />
                <DropdownLink
                  v-if="!props.item.active"
                  :text="$t('global:activate')"
                  @click="toggleActive(props.item.id, props.item.active)"
                />
                <DropdownLink
                  v-else
                  :text="$t('global:deactivate')"
                  @click="confirmDeactivate(props.item.id)"
                />

                <DropdownLink
                  v-show="props.item.sessionType === 'open'"
                  :text="$t('solutions:menu.direct-url')"
                  @click="doCopy(props.item.id)"
                />

                <!--
                <dropdown-link :text="$t('global:remove')" @click="confirmRemove(props.item.id, props.index)"></dropdown-link>
-->
              </Dropdown>
            </td>
          </tr>
        </template>
      </Datatable>
      <Pagination
        :active-page="pagination.page"
        :page-count="pagination.lastPage"
        @firstPage="firstPage"
        @lastPage="lastPage"
        @nextPage="nextPage"
        @previousPage="prevPage"
      />
    </div>
    <div
      v-else
      class="center solutions-table"
    >
      <p>Nenhuma solução encontrada para o termo pesquisado</p>
    </div>
    <ModalConfirm
      :active="modalRemoveOpen"
      :title="$t('solutions.manage:remove.title')"
      :description="$t('solutions.manage:remove.description')"
      @confirmAction="remove"
      @cancelAction="cancelRemove"
    />
    <ModalConfirm
      :active="modalDeactivateOpen"
      :title="$t('solutions.manage:deactivate.title')"
      :description="$t('solutions.manage:deactivate.description')"
      @confirmAction="deactivate"
      @cancelAction="cancelDeactivate"
    />
    <Modal
      class="modal-sessions-filters"
      :active="modalSessionsFiltersOpen"
      :cancel="true"
      @close="closeFilterModal"
    >
      <div class="modal-form modal-sessions-filters">
        <h2 class="modal-title">
          {{ $t('sessions.list:filter.title') }}
        </h2>
        <span class="modal-subtitle">{{ $t('sessions.list:filter.description') }}</span>
        <form @submit.prevent="filter">
          <SelectField
            v-model="filteredForm.active"
            class="sessions-solutions-field"
            dark
            :label="$t('solutions.manage:filtered-form.input.1')"
            :items="filteredFormLabels.active"
          />

          <SelectField
            v-model="filteredForm.show_on_portal"
            class="sessions-solutions-field"
            dark
            :label="$t('solutions.manage:filtered-form.input.2')"
            :items="filteredFormLabels.showOnPortal"
          />

          <SelectField
            v-model="filteredForm.has_certificate"
            class="sessions-solutions-field"
            dark
            :label="$t('solutions.manage:filtered-form.input.3')"
            :items="filteredFormLabels.hasCertificate"
          />

          <SelectField
            v-model="filteredForm.has_survey"
            class="sessions-solutions-field"
            dark
            :label="$t('solutions.manage:filtered-form.input.4')"
            :items="filteredFormLabels.hasSurvey"
          />

          <SelectField
            v-model="filteredForm.enrollment_closed"
            class="sessions-solutions-field"
            dark
            :label="$t('solutions.manage:filtered-form.input.5')"
            :items="filteredFormLabels.enrollmentClosed"
          />

          <div class="form-buttons">
            <Action
              primary
              large
              dark
              fixed-width
              type="button"
              :text="$t('sessions.list:btn.clear.filter.action')"
              @click="clearFilters"
            />
            <Action
              secondary
              large
              dark
              submit
              fixed-width
              type="button"
              :text="$t('sessions.list:btn.filter.action')"
            />
          </div>
        </form>
      </div>
    </Modal>
  </div>
</template>

<style>
.tooltip {
  color: #005EB8;
  z-index: 9999;
  font-size: 0.85rem;
  margin: 1px solid #005EB8;
}

.tooltip p {
  margin-top: 5px;
}

.tooltip.info .tooltip-inner, .popover-inner div {
  background: linear-gradient(to bottom, #00B1E6 0%,#00426E 100%);
  padding: 24px;
  border-radius: 5px;
  box-shadow: 0 5px 30px rgba(black, .1);
  max-width: 500px;
  color: white;
}

.popover-inner div p {
  padding-bottom: 10px;
}
.popover-inner div span {
  display: block;
}

.tooltip .info .tooltip-arrow {
  border-color: #005EB8;
}

.warning {
  cursor: pointer;
  margin-left: 10px;
}

.form-buttons {
  display: flex;
  justify-content: space-around;
}
</style>
